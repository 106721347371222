/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

import TitleImg from "../../images/D365-financial-management.png"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <div className="industries-and-finance summary-info">
      <p>
        Microsoft Business Central for financial management gives your people a
        way to raise the visibility of financial metrics and the effectiveness
        of financial management throughout your organization-all using familiar
        tools and existing skills. Strong financial management, made possible by
        Microsoft Dynamics, enables your people to establish a firm foundation
        to realize the potential of your business through strong, manageable
        growth. And Microsoft Business Central works like the familiar
        technologies you already own.
        <br />
        <br />
        Microsoft Business Central offers a full general ledger solution that
        provides the basic facilities necessary for setting up a company and
        posting to the general ledger: chart of accounts, general journals,
        sales tax, recurring journals, and source codes. Microsoft Business
        Central makes it easy to accomplish a spectrum of financial management
        tasks, such as:
      </p>
      <List
        className="dark"
        items={[
          {
            text: `Setting up accounts in the chart of accounts. For each account established, there is a balance/ budget window that shows budget figures and/or actual figures for the entire chart of accounts, or for individual accounts.`,
          },
          {
            text: `Defining the fiscal year as beginning on any date and dividing it into accounting periods.`,
          },
          {
            text: `Viewing continually updated balances and net changes.`,
          },
          {
            text: `Checking how journal entries will affect the liquid accounts before they are posted. Easily track posted general ledger entries and reverse entries that were posted with incorrect information.`,
          },
          {
            text: `Creating budgets in a matrix window, for a day, week, month, quarter, year, or any accounting period.`,
          },
          {
            text: `Entering figures in the general journal in any currency.`,
          },
          {
            text: `Developing budgets based on department, project, or business unit.`,
          },
        ]}
      />
    </div>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Financial Management"
      description="Strong financial management, made possible by Microsoft Dynamics, enables your people to realize the potential of your business through strong, manageable growth."
      subTitle="Measure Financial Effectiveness and Performance"
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      summaryInfo={summaryInfo}
      features={{
        title:
          "Microsoft Business Central provides a wealth of financial management features, including:",
        items: [
          {
            title: "General Ledger",
            text: `Gives you flexible options and tools for setting up a company and posting for charts of accounts, general journals, sales tax jurisdictions, recurring journals, and source codes. You can customize your general ledger for inter-company transactions and inter-fund accounting that spans geographies, or organize financial operations by company and consolidate different charts of accounts into one merged or parent company.`,
          },
          {
            title: "Fixed Assets",
            text: `Control and manage the entire lifecycle of your fixed assets-from acquisition to disposal-to maximize the value of your business investments. Fixed Assets equips you to accurately record, track, depreciate, and dispose of your company’s assets with minimal effort and maximum flexibility. Define information for an asset and group assets. Identifies an unlimited number of depreciation books for each fixed asset, and describes the required depreciation conditions. Businesses can choose from several standard depreciation methods: straight-line, declining-balance, and accelerated. Also sets up maintenance information and record maintenance performed on fixed assets.`,
          },
          {
            title: "Multicurrency",
            text: `Expand into international markets with confidence and handle foreign currencies with ease. Whether you need to share financial data with international partners or manage accounts with customers and vendors in other countries, Multicurrency reduces the complexity of global transactions.`,
          },
          {
            title: "Receivables and Payables",
            text: `Defines flexible payment terms, discount periods, and payment methods, and takes advantage of other functionality including defining flexible payment application policies and finance charge terms. Reverses incorrect applications and customer and vendor transactions posted manually in journals, including all related ledger entries, VAT entries, and bank account entries.`,
          },
          {
            title: "FlowFields",
            text: `Helps ensure that users receive the most up-to-date financial data and continually updates and recalculates the contents of a FlowField online. Users can drill down from all FlowField amounts to view which entries were included in the calculation of the amount.`,
          },
          {
            title: "Lookup Buttons",
            text: `Gives users direct access to information in a table. For example, from a journal line users only have to enter information correctly once, and it can be reused consistently on all subsequent occasions. This makes data input quick because there is no need for data reentry.`,
          },
          {
            title: "Cash Manager",
            text: `Includes bank account management, enabling organizations to create, modify, and delete bank accounts, transfer between bank accounts, and set up customer/vendor bank account cards. Cash Manager automates and controls the entire check-writing process and enables users to write checks in more than one currency.`,
          },
          {
            title: "Traceability",
            text: `Helps ensure all transactions have a complete audit trail, so even complex business transactions become transparent. The Change Log records all direct user changes to master data. A chronological list of all changes to any field, in any table shows who made the changes.`,
          },
          {
            title: "Security Features and Business Rules",
            text: `Specifies which personnel and roles may see which information using record-level security. For example, a salesperson may see only sales figures for their region or managers may see only budgetary amounts for their departments.`,
          },
          {
            title: "Traceability",
            text: `Creates intercompany transactions between any two companies within a group controlled by the same legal entity. The Intercompany Transaction report documents all intercompany transactions, simplifies the intercompany reconciliation process, and provides an audit trail.`,
          },
          {
            title: "VAT, Sales Tax, and Other Use Taxes",
            text: `Uses posting groups to automatically calculate VAT, sales tax, excise tax, or other use taxes for customers and vendors. Specify whether unit prices on sales and purchase lines should include or exclude the tax. For VAT, check that VAT registration.`,
          },
        ],
      }}
      infoList={[
        {
          title: "Empower Financial Managers to Succeed",
          text: `Sound, responsible financial management is at the heart of business success. Now you can give people across your organization the tools to reach their professional goals and keep your organization’s financial operations and performance on track. Microsoft Dynamics is designed to help people work efficiently, focus on tasks that add the most value, and collaborate productively.`,
        },
        {
          title: "Compete More Effectively with Better Insight into Financials",
          text: `Microsoft Business Central for financial management can provide tools and insight to help your team make more informed decisions. These tools and insights can help your company set a competitive direction for your business. With Microsoft Dynamics financial solutions, you can transform financial and executive management of your company into a dependable, efficient process.`,
        },
        {
          title: "Realize Your Vision for Growth",
          text: `Microsoft Dynamics solutions for financial management can help you promote the kind of business growth you want to bring about. Business and technical expertise from Microsoft and its many partners can assist you in gaining the best value from your technology as your requirements change.`,
        },
      ]}
    />
  )
}
